import React, { createRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { renderDuration } from '../../helpers.js';

const PlayBar = props => {
  const currentLabelRef = createRef();
  const jumpToLabelRef = createRef();
  const [ hovered, setHovered ] = useState(false);
  const [ label, setLabel ] = useState(false);
  const [ position, setPosition ] = useState(false);
  const [ jumpToPosition, setJumpToPosition ] = useState(false);

  useEffect(() => {
    setPosition(_calculateLabelPosition(props.currentTime / props.duration * window.innerWidth));
  }, [props.currentTime]);

  const _handleMouseMove = e => {
    const targetSecond = (e.clientX / window.innerWidth) * props.duration;

    setLabel(renderDuration(targetSecond));
    setJumpToPosition(_calculateLabelPosition(e.clientX));
  };

  const _calculateLabelPosition = clientX => {
    const labelWidth = 70;

    if(clientX + 25 > (window.innerWidth - labelWidth)){
      // if label would hit right edge, stop moving before it overlaps
      return window.innerWidth - labelWidth - 25;
    }else if(clientX - 80 < labelWidth){
      // if label would hit left edge, delay moving before it overlaps
      return labelWidth + 80;
    }else{
      return clientX;
    }
  }

  const _handleClick = e => {
    const targetSecond = (e.clientX / window.innerWidth) * props.duration;
    props.onPositionChange(targetSecond);
  };

  const _handleMouseEnter = e => setHovered(true);
  const _handleMouseLeave = e => setHovered(false);

  if(!position){
    return null;
  }
  
  return (
    <div className={classNames('play-bar' ,{
       'hovered': hovered
     })}
     onMouseMove={e => _handleMouseMove(e)}
     onMouseEnter={e => _handleMouseEnter(e)}
     onMouseLeave={e => _handleMouseLeave(e)}
     onClick={e => _handleClick(e)}
     >
      <div className="play-bar__track" style={{width: `${(props.currentTime / props.duration) * 100}%`}} >
        <div className="play-bar__jump-to-label" style={{left: `${jumpToPosition}px`}} ref={jumpToLabelRef}>
          {label}
        </div>
        <div className="play-bar__current-label" style={{left: `${position}px`}} ref={currentLabelRef}>
          {renderDuration(props.currentTime)}
        </div>
      </div>
    </div>
  )
}

export default PlayBar;
