import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { short as Logo } from '../parts/Logo';
import { getPitch } from "../../selectors/pitchSelectors";
import { findPoolById } from "../../selectors/poolSelectors";
import { findVideoById } from "../../selectors/videoSelectors";
import { unescape } from 'lodash';

const useHeaderButtonLabel = () => {
  const {pitchId, poolId, videoId} = useParams();
  const pool = useSelector((state) => findPoolById(state, pitchId));

  let label;
  if (pitchId) {
    label = 'Download All';
  }
  if (poolId) {
    if (pool && pool.name) {
      label = `Download ${pool.name}`
    }
  }
  if (videoId) {
    label = '';
  }
  return label
};

const useHeaderButtonLink = () => {
  const {pitchId, poolId, videoId} = useParams();
  const pitch = useSelector(getPitch);
  const pool = useSelector((state) => findPoolById(state, poolId));
  const video = useSelector((state) => findVideoById(state, videoId));

  let link;
  if (pitchId && pitch) {
    link = pitch.download;
  }
  if (poolId && pool) {
    if (pool) {
      link = pool.download;
    }
  }
  if (videoId && video) {
    link = video.path_absolute;
  }

  return unescape(link);
};

const Header = () => {
  const pitch = useSelector(getPitch);
  const headerButtonLabel = useHeaderButtonLabel();
  const headerButtonLink = useHeaderButtonLink();
  return (
    <div className="header">
      <div className="header-info-section">
        <Link to={`/pitch/${pitch.UID}`} className="logo">
          <Logo />
        </Link>
        {pitch && (
          <div className="header__label">
            <span>{`${pitch.name} for `}</span>
            <span className="agency">{pitch.agency}</span>
          </div>
        )}
      </div>
      {headerButtonLabel !== "" && (
        <div className="header__header-actions">
          <a href={headerButtonLink} download target="_blank" rel="noopener noreferrer" className='download-link'>
            {headerButtonLabel}
          </a>
        </div>
      )}
    </div>
  )
};

export default Header;
