import React, { createRef, useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import classNames from 'classnames';
import isTouchDevice from 'is-touch-device';

import Header from '../parts/Header';
import PlayBar from '../parts/PlayBar';
import VideoCursor from '../parts/VideoCursor';
import { Fullscreen, Unfullscreen } from '../parts/Svg';

import { requestFullscreen, exitFullscreen } from "../../helpers";
import useInterval from "../../hooks/useInterval";
import { findVideoById } from "../../selectors/videoSelectors";
import { useSelector } from 'react-redux';
import VideoChangeControls from "../parts/VideoChangeControls";

const Video = () => {
  const history = useHistory();
  const params = useParams();
  const videoRef = createRef();

  const [ currentTime, setCurrentTime ] = useState(false);
  const [ duration, setDuration ] = useState(false);
  const [ hovered, setHovered ] = useState(false);
  const [ muted, setMuted ] = useState(true);
  const [ paused, setPaused ] = useState(false);

  const video = useSelector((state) => findVideoById(state, params.videoId));
  const isFullscreen = window.outerHeight === window.screen.height && !isTouchDevice();

  useEffect(() => {
    document.addEventListener('keydown', _handleKeyPress);

    return function cleanup(){
      document.removeEventListener('keydown', _handleKeyPress);
    }
  });

  useInterval(() => videoRef.current && setCurrentTime(videoRef.current.currentTime), 50);

  const _handlePlay = () => videoRef.current.play();
  const _handlePause = () => videoRef.current.pause();
  const _handlePlayPauseClick = () => {
    videoRef.current.paused ? _handlePlay() : _handlePause();
    setPaused(videoRef.current.paused);
  };
  const _handlePositionChange = targetSecond => videoRef.current.currentTime = targetSecond;
  const _handleOnCanPlay = () => setDuration(videoRef.current.duration);
  const _handleSoundClick = () => {
    videoRef.current.muted = !videoRef.current.muted;
    setMuted(videoRef.current.muted);
  };
  const _handleFullScreen = e => {
    if(isTouchDevice()){
      requestFullscreen(videoRef.current);
    }else{
      if(isFullscreen){
        exitFullscreen();
      }else{
        requestFullscreen();
      }
    }
  }
  const _handleMouseEnter = e => setHovered(true);
  const _handleMouseLeave = e => setHovered(false);
  // const _handleEnded = e => {
  //   const nextVideoLink = useNextVideoLink();
  //
  //   history.push(nextVideoLink);
  // }

  const _handleKeyPress = e => {
    switch (e.code) {
      case 'ArrowLeft':
        videoRef.current.currentTime -= 5;
        break;
      case 'ArrowRight':
        videoRef.current.currentTime += 5;
        break;
      case 'Space':
        _handlePlayPauseClick();
        break;
      case 'KeyM':
        _handleSoundClick();
        break;
      case 'Escape':
        const {pitchId, poolId } = params;

        history.push(`/pitch/${pitchId}/${poolId}`);

        break;
      default:
    }
  }

  return (
    <React.Fragment>
      <Header />
      <div className="single-video">
        <div className={classNames('single-video__container', {'hovered': hovered})}>
          {video.path &&
            <React.Fragment>
              <video
                ref={videoRef}
                className={classNames('video', {
                  'video--paused': paused
                })}
                type={video.type}
                src={'https://pitch-backend.akkurat.tv' + video.path}
                autoPlay
                playsInline
                poster={video.thumbnail || null}
                onMouseEnter={() => _handleMouseEnter()}
                onMouseLeave={() => _handleMouseLeave()}
                onClick={() => _handlePlayPauseClick()}
                onCanPlay={() => _handleOnCanPlay()}
              />
              <PlayBar
                currentTime={currentTime}
                duration={duration}
                onPositionChange={targetSecond => _handlePositionChange(targetSecond)}
                />
              {!isTouchDevice() && <VideoCursor paused={paused}/>}
              <div className={'video__sound'} onClick={() => _handleSoundClick()}>
                {muted ? 'Sound is off' : 'Sound is on'}
              </div>
              <div className={'video__fullscreen'} onClick={() => _handleFullScreen()}>
                {isFullscreen ? <Unfullscreen /> : <Fullscreen />}
              </div>
              <VideoChangeControls currentVideo={video} />
            </React.Fragment>
          }
        </div>
      </div>
    </React.Fragment>
  );
};

export default Video;
