import React from 'react';
import {useParams} from 'react-router-dom'
import classNames from 'classnames';

const Page = ({children, name}) => {
  const {videoId} = useParams();

  return (
    <div className={classNames('page', name, {
      'video': videoId
    })}>
      <div className="page__inner">
        {children}
      </div>
    </div>
  )
};

export default Page;
