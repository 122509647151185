import React from 'react';

const VideoTiles = ({children}) => {
  return (
    <div className="video-tiles">
      {children}
    </div>
  )
}

export default VideoTiles;
