import React from 'react';

import { Route } from "react-router-dom";

import Pitch from './pages/Pitch';
import NotFound from './pages/NotFound';

import '../../scss/App.scss';

const App = () => {
  return (
    <div className="app">
      <Route path="/pitch/:pitchId" component={Pitch} />
      <Route path='/login' component={() => {
        window.location.href = 'https://pitch-backend.akkurat.tv/backend';
        return null;
      }}/>
    </div>
  );
}
export default App;
