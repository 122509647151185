import React, { useState, useEffect } from 'react'
import akkuratLogo from '../../../static/akkurat-logo.png'
import { useSelector } from 'react-redux';
import { getPitch } from "../../selectors/pitchSelectors";
import classNames from 'classnames';

const PitchIntro = ({title, client, description}) => {
  const pitch = useSelector(getPitch);
  const [ pitchReady, setPitchReady ] = useState(false);

  useEffect(() => setTimeout(() => setPitchReady(true), 1500), []);

  const poolNumber = pitch.videos_merged ? Object.keys(pitch.videos_merged).length : 0;
  const poolNumberLabel = poolNumber === 1 ? 'Artist' : 'Artists';

  return (
    <div className={classNames('pitch-intro', {
      'pitch-intro--ready': pitchReady
    })}>
      {pitch && (
        <div className="pitch-intro__inner">
          <img src={akkuratLogo} className='logo' alt='Akkurat-Logo' />
          <div>
            <h1 className='title'>{pitch.name}</h1>
            <h2 className='client'>{pitch.agency}</h2>
            <p className='description'>
            This is our selection of {poolNumber} {poolNumberLabel}:
            </p>
          </div>
        </div>
      )}
    </div>
  )
};

export default PitchIntro
