import {
  FETCH_PITCH_REQUEST,
  FETCH_PITCH_SUCCESS,
  FETCH_PITCH_FAILURE,
} from '../actions';

const defaultState = {
  pitch: false,
  pools: false,
  videos: false,
  loading: false,
  error: false
};

const reducer = (state = defaultState, action) => {
  switch(action.type){
    case FETCH_PITCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PITCH_SUCCESS:
      return {
        ...state,
        pitch: action.pitch.pitch,
        pools: action.pitch.pools,
        videos: action.pitch.videos,
        loading: false,
      };
    case FETCH_PITCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
}

export default reducer;
