import React from 'react';

export const Pause = () =>
  <svg width="40px" height="40px" viewBox="0 0 40 40">
    <g id="pause" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="Rectangle" fill="#FFFFFF" x="5" y="0" width="10" height="40"></rect>
      <rect id="Rectangle-Copy" fill="#FFFFFF" x="25" y="0" width="10" height="40"></rect>
    </g>
  </svg>
;

export const Play = () =>
  <svg width="40px" height="40px" viewBox="0 0 40 40">
    <g id="play" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon id="Path-2" fill="#FFFFFF" points="0 0 0 40 40 20"></polygon>
    </g>
  </svg>
;

export const ArrowLeft = () =>
<svg width="801px" height="439px" viewBox="0 0 801 439">
  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
    <g transform="translate(-71.000000, -51.000000)" stroke="#ffffff" strokeWidth="60">
      <path d="M89.4996611,270 L88.5,270 L89.4996611,270 L291.160002,68.2712907 L89.4996611,270 Z M89.4996611,270 L854.342934,270 L89.4996611,270 L88.5,271 L89.4996611,270 Z M291.160002,471.778553 L88.5,271 L291.160002,471.778553 Z" ></path>
    </g>
  </g>
</svg>
;

export const ArrowRight = () =>
<svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="9"
    fill="none"
    viewBox="0 0 11 9"
  >
    <path
      fill="#fff"
      d="M10.354 4.854a.5.5 0 000-.708L7.172.964a.5.5 0 00-.707.708L9.293 4.5 6.465 7.328a.5.5 0 10.707.708l3.182-3.182zM.748 5H10V4H.748v1z"
    ></path>
</svg>
;

export const Fullscreen = () =>
<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="14"
  viewBox="0 0 20 14"
>
  <g fill="none" fillRule="evenodd" stroke="#ffffff" strokeWidth="1">
    <path d="M4 1L1 1 1 13.0917094 4 13.0917094"></path>
    <path d="M16 1L19 1 19 13.0917094 16 13.0917094"></path>
  </g>
</svg>;

export const Unfullscreen = () =>
<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="14"
  viewBox="0 0 20 14"
>
  <g fill="none" fillRule="evenodd" stroke="#ffffff" strokeWidth="1">
    <g transform="translate(.5 6.9)">
      <path d="M0 6.625L7.5 1.414"></path>
      <path
        d="M3.3 1L7.3 1 7.3 5.00135295"
        transform="rotate(10 5.3 3)"
      ></path>
    </g>
    <g transform="rotate(-180 9.75 3.6)">
      <path d="M0 6.625L7.5 1.414"></path>
      <path
        d="M3.3 1L7.3 1 7.3 5.00135295"
        transform="rotate(10 5.3 3)"
      ></path>
    </g>
  </g>
</svg>;
