import React, {useEffect, useState} from 'react';
import {
  Route,
  Switch,
  useParams,
  useLocation,
} from "react-router-dom";
import PitchIntro from '../parts/PitchIntro'
import { useDispatch } from 'react-redux';
import { fetchPitch } from '../../actions';
import {
  CSSTransition,
  TransitionGroup
} from 'react-transition-group';

import Page from './Page';
import PitchOverview from './PitchOverview';
import PitchPool from './PitchPool';
import Video from './Video';

const Pitch = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const {pitchId} = useParams();
  const [introTransition, setIntroAnim] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIntroAnim(false);
    }, 5000)
  }, []);
  useEffect(() => {
    dispatch(fetchPitch(pitchId))
  }, []);

  return (
    <div className="pitch">
      <CSSTransition
        in={introTransition}
        classNames="pitch-intro"
        unmountOnExit={false}
        enter={false}
        timeout={0}
      >
        <PitchIntro />
      </CSSTransition>
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          appear={true}
          timeout={3000}
          classNames="transition"
          unmountOnExit
        >
          <Switch location={location}>
            <Route exact path='/pitch/:pitchId/' >
              <Page name="pitch-overview">
                <PitchOverview />
              </Page>
            </Route>
            <Route path='/pitch/:pitchId/:poolId/:videoId'>
              <Page name="video">
                <Video />
              </Page>
            </Route>
            <Route path='/pitch/:pitchId/:poolId'>
              <Page name="pitch-pool">
                <PitchPool/>
              </Page>
            </Route>
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  )
};


export default Pitch;
