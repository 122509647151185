import moment from 'moment';

export const renderDuration = duration => {
	const ms = Math.floor(moment.duration(duration, 'seconds').milliseconds()).toString().substr(0,2).toString().padStart(2, 0);
	const s = moment.duration(duration, 'seconds').seconds().toString().padStart(2, 0);
	const m = moment.duration(duration, 'seconds').minutes().toString().padStart(2, 0);

	return `${m}:${s}:${ms}`;
}

export const requestFullscreen = (element = document.documentElement) => {
	if(element.requestFullscreen){
		element.requestFullscreen();
	}else if(element.mozRequestFullScreen){
		element.mozRequestFullScreen();
	}else if(element.webkitEnterFullscreen){
		element.webkitEnterFullscreen();
	}else if(element.msRequestFullscreen){
		element.msRequestFullscreen();
	}
}

export const exitFullscreen = (element = document) => {
	if(element.exitFullscreen){
		element.exitFullscreen();
	}else if(element.mozCancelFullScreen){
		element.mozCancelFullScreen();
	}else if(element.webkitExitFullscreen){
		element.webkitExitFullscreen();
	}else if(element.msExitFullscreen){
		element.msExitFullscreen();
	}
}
