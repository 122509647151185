import React from 'react';
import Header from '../parts/Header';
import ReactMarkdown from 'react-markdown'

import {
  useLocation,
  useParams,
} from "react-router-dom";

import {getPools, findPoolById} from "../../selectors/poolSelectors";
import { useSelector } from 'react-redux';

import VideoTiles from '../parts/VideoTiles';
import VideoTile from '../parts/VideoTile';
import PitchPoolNavigation from '../parts/PitchPoolNavigation';
import usePoolVideos from "../../hooks/usePoolVideos";

const PitchPool = () => {
  const location = useLocation();
  const params = useParams();
  const pools = useSelector(getPools);
  const pool = useSelector((state) => findPoolById(state, params.poolId));
  const poolVideos = usePoolVideos();

  console.log(process.env)
  return (
    <div className="pitch-pool">
      <Header />
      <PitchPoolNavigation pools={pools} />
      {pool &&
        <>
          <div className="pitch-pool__header">
            <div className="pitch-pool__title">
              {pool.name}
            </div>

            {(pool.text && pool.text !== "") && (
              <ReactMarkdown className="pitch-pool__biography">
                {pool.text}
              </ReactMarkdown>
            )}
          </div>
          <div className="pitch-pool__subheader">
            {(pool.link && pool.link !== "") && (
              <a href={pool.link} target="_blank" rel="noreferrer noopener">Instagram</a>
            )}
            {(pool.biography && pool.biography !== "") && (
              <a href={`${process.env.REACT_APP_BACKEND_BASE_URI}${pool.biography}`} target="_blank" rel="noreferrer noopener">Biography</a>
            )}
          </div>
        </>
      }

      <VideoTiles>
        {poolVideos.map((video, i) => (
          <VideoTile
            size={i === 0 ? 'large' : null}
            layout="center"
            key={video.name}
            to={`${location.pathname}/${video.ID}`}
            title={video.name}
            video={video}
           />
        ))}
      </VideoTiles>
    </div>
  );
};

export default PitchPool;
