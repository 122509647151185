import React from 'react';
import {useSelector} from 'react-redux';
import VideoTiles from '../parts/VideoTiles';
import VideoTile from '../parts/VideoTile';
import Header from '../parts/Header';
import {getPools} from "../../selectors/poolSelectors";
import {getPitch} from "../../selectors/pitchSelectors";
import {useParams} from 'react-router-dom';

const PitchOverview = () => {
  const pools = useSelector(getPools);
  const pitch = useSelector(getPitch);
  const {pitchId} = useParams();
  return (
    <div className="pitch-overview">
      <Header />
      <VideoTiles>
        {pools.map((pool, i) => {
          const poolVideos = pitch.videos_merged[pool.name];

          if(!poolVideos){
            return false;
          }
          
          return (
            <VideoTile
              key={pool.ID}
              to={`/pitch/${pitchId}/${pool.ID}`}
              title={pool.name}
              subtitle={poolVideos.length + ' Videos'}
              video={poolVideos[0]}
             />
          )
        })}
      </VideoTiles>
    </div>
  )
};

export default PitchOverview;
