import React, { createRef, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const VideoTile = props => {
  const videoRef = createRef();
  const [ hovered, setHovered ] = useState(false);
  const [ videoReady, setVideoReady ] = useState(false);

  const _handlePlay = e => {
    videoRef.current && videoRef.current.play();
  };

  const _handlePause = e => {
    videoRef.current && videoRef.current.pause();
  };

  const _handleMouseEnter = e => {
    _handlePlay();
    setHovered(true);
  };
  const _handleMouseLeave = e => {
    _handlePause();
    setHovered(false);
  };
  const _handleOnCanPlay = e => setVideoReady(true);

  return (
    <Link
      to={props.to}
      className={classNames('video-tile', {
        'video-tile--hovered': hovered,
        'video-tile--ready': videoReady,
        'video-tile--large': props.size === 'large',
        'video-tile--center': props.layout === 'center'
      })}
      onMouseEnter={e => _handleMouseEnter(e)}
      onMouseLeave={e => _handleMouseLeave(e)}
      >
      <div className="video-tile__overlay">
        <div className="video-tile__title">
          {props.title}
        </div>
        <div className="video-tile__subtitle">
          {props.subtitle}
        </div>
      </div>
      {props.video.path && (
        <video
          ref={videoRef}
          className="video-tile__video"
          type={props.video.type}
          src={'https://pitch-backend.akkurat.tv' + props.video.path}
          playsInline
          poster={props.video.thumbnail || null}
          muted
          onCanPlay={e => _handleOnCanPlay(e)}
        />
      )}
      {props.video.thumbnail && (
        <img src={'https://pitch-backend.akkurat.tv/' + props.video.thumbnail} className="video-tile__thumbnail" alt='' />
      )}
    </Link>
  )
};

export default VideoTile;
