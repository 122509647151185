import React, { useState, useEffect } from 'react';

const Cursor = props => {
  const [ position, setPosition ] = useState({});

  const _handleMouseMove = e => setPosition({
    top: e.clientY,
    left: e.clientX,
  });

  const _attachEventListener = () => window.addEventListener('mousemove', _handleMouseMove);
  const _detachEventListener = () => window.removeEventListener('mousemove', _handleMouseMove);

  useEffect(() => {
    _attachEventListener();

    return function cleanup(){
      _detachEventListener()
    }
  });


  return (
    <div className="cursor"
      style={{
        left: position.left,
        top: position.top
      }}
      >
      {props.paused ? 'Play' : 'Pause'}
    </div>
  );
}

export default Cursor;
