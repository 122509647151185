import React from 'react'
import {useHistory, useParams} from "react-router";
import usePoolVideos from "../../hooks/usePoolVideos";

function wrapAround(n, m) {
  return n >= 0 ? n % m : ((n % m) + m) % m;
}

export const useNextVideoLink = () => {
  const poolVideos = usePoolVideos();
  const {pitchId, poolId, videoId} = useParams();

  const currentVideoIndex = poolVideos.findIndex(video => video.ID === videoId);
  const nextVideoIndex = wrapAround(currentVideoIndex + 1, poolVideos.length);
  const nextVideoId = poolVideos[nextVideoIndex].ID;

  return `/pitch/${pitchId}/${poolId}/${nextVideoId}`;
}

export const usePrevVideoLink = () => {
  const poolVideos = usePoolVideos();
  const {pitchId, poolId, videoId} = useParams();

  const currentVideoIndex = poolVideos.findIndex(video => video.ID === videoId);
  const prevVideoIndex = wrapAround(currentVideoIndex - 1, poolVideos.length);
  const prevVideoId = poolVideos[prevVideoIndex].ID;

  return `/pitch/${pitchId}/${poolId}/${prevVideoId}`;
}

const NextVideoButton = () => {
  const history = useHistory();
  const nextVideoLink = useNextVideoLink();

  return (
     <div
       className="video-controls__next-video"
       onClick={() => history.push(nextVideoLink)}
     >
      Next
     </div>
   )
};

const PrevVideoButton = ({currentVideo}) => {
  const history = useHistory();
  const prevVideoLink = usePrevVideoLink();

  return (
    <div
      className="video-controls__prev-video"
      onClick={() => history.push(prevVideoLink)}
    >
      Previous
     </div>
  )
};

const VideoChangeControls = (props) => {
  return (
    <div className="video-change-controls__container">
      <PrevVideoButton currentVideo={props.currentVideo} />
      <NextVideoButton currentVideo={props.currentVideo}/>
    </div>
  )
};

export default VideoChangeControls
