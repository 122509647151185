import {useParams} from 'react-router-dom';
import {useSelector} from "react-redux";
import {findPoolById} from "../selectors/poolSelectors";
import {getPitchVideosByPoolName} from "../selectors/pitchSelectors";

const usePoolVideos = () => {
  const {poolId} = useParams();
  const activePool = useSelector((state) => findPoolById(state, poolId));
  return useSelector((state) => getPitchVideosByPoolName(state, activePool.name)) || [];
};

export default usePoolVideos
