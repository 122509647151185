import axios from 'axios';

export const FETCH_PITCH_REQUEST = 'FETCH_PITCH_REQUEST';
export const FETCH_PITCH_SUCCESS = 'FETCH_PITCH_SUCCESS';
export const FETCH_PITCH_FAILURE = 'FETCH_PITCH_FAILURE';

export const fetchPitchRequest = () => {
  return{
    type: FETCH_PITCH_REQUEST
  };
};

export const fetchPitchSuccess = pitch => {
  console.log(pitch);
  return {
    type: FETCH_PITCH_SUCCESS,
    pitch
  };
};
export const fetchPitchFailure = error => {
  return {
    type: FETCH_PITCH_FAILURE,
    error
  };
};

export const fetchPitch = id => {
  return dispatch => {
    dispatch(fetchPitchRequest());

    return axios(`https://pitch-backend.akkurat.tv/json/rest/pitch.html?UID=${id}`)
      .then(response => dispatch(fetchPitchSuccess(response.data)));
  };
};
