import React from 'react';
import { NavLink } from 'react-router-dom';

const PitchPoolNavigation = props => (
  <ul className="pitch-pool__navigation">
    {props.pools && props.pools.map(pool => (
      <li key={pool.ID}>
        <NavLink activeClassName="active" to={pool.ID}>{pool.name}</NavLink>
      </li>
    ))}
  </ul>
);

export default PitchPoolNavigation;
